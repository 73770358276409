

import React, { useState } from 'react';

import { colors, siteInfo } from "../config";

import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';

import {
    MenuOutlined,
    DownOutlined,
    CloseCircleOutlined
} from '@ant-design/icons'
import Button from "./Button";
import Navbar from "./Navbar";

import connectorLogoIcon from "../assets/connector-logo-icon.png"
import whiteLogo from "../assets/beyaz-şarjon-logo.png"
import sarjonLogo from "../assets/sarj-on-logo.png"

//const logo = "https://app.varnacharging.com/dist/images/eu-varna/logo_large.png"

function Container(props) {


    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);


    return (
        <div>
            <div style={{
                //   backgroundColor: colors.primary,
                backgroundColor: '#FFF',
                boxShadow: '1px 5px 20px 1px #888888',
                position: 'absolute',
                height: 50,
                top: 0, left: 0,
                right: 0,
                zIndex: 99999
            }}>

                <header className="app-header"
                    style={{
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',

                    }}>

                    <a href="https://sarjon.com.tr/">Anasayfa</a>

                    {/* <div>
                        <MenuOutlined onClick={showSidebar} style={{ color: colors.white }} />
                    </div> */}


                    <img title="Şarjon" style={{ height: 36 }} src={sarjonLogo} className="header-logo" alt="logo" />
                    {/* <div style={{
                        // padding: 5,
                        border: '1px solid #FFF',
                        width: 32, height: 32,
                        borderRadius: 16,
                        alignItems: 'center',
                        justifyContent: 'center', display: 'flex'
                    }}>
                        <img style={{ height: 26 }} src={connectorLogoIcon} className="header-logo" alt="logo" />
                    </div> */}

                    <div />
                    <div style={{ display: 'none' }}>
                        {/* {  <Button title="TR" color={colors.primary} backgroundColor={"#FFF"} icon={<DownOutlined style={{ color: colors.primary }} />} />} */}
                        <Button backgroundColor={"#FFF"} title="Giriş" color={colors.primary} />

                    </div>
                </header>

                <nav style={{ backgroundColor: colors.primary, marginTop: 50 }} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                            <Link to='#' className='menu-bars'>
                                <img style={{ maxWidth: 180 }} src={whiteLogo} />
                                {/* { <AiIcons.AiOutlineClose />} */}
                            </Link>
                        </li>

                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>

            </div>

            {props.children}


        </div>

    );
}

export default Container;
