

/*
  _____                .___.__                             
  _/ ____\___________  __| _/|__|   ____________ ___________ 
  \   __\/ __ \_  __ \/ __ | |  |  /  _ \___   // __ \_  __ \
   |  | \  ___/|  | \/ /_/ | |  | (  <_> )    /\  ___/|  | \/
   |__|  \___  >__|  \____ | |__|  \____/_____ \\___  >__|   
             \/           \/                  \/    \/       
                                                ferdiozer.com
*/

import axios from 'axios'
import { ampecoToken } from '../config'

const endpoints = {
    locations: `https://sarjon.com.tr/api/ampeco-api-locations.php`,
    points: `https://sarjon.com.tr/api/ampeco-api-points.php`,
    sessions: `https://sarjon.com.tr/api/ampeco-api-sessions.php`,
    sessionsUnavaliable: `https://sarjon.com.tr/api/ampeco-api-sessions-unavaliable-status.php`,
}

const headers = {
    headers: {
        Authorization: `Bearer ${ampecoToken}`,
        "Content-Type": 'application/json'
    }
}


export function getAllLocations() {
    const url = endpoints.locations
    return axios.get(url, headers)
}


export function getAllPoints() {
    const url = endpoints.points
    return axios.get(url, headers)
}


export function getAllSessions() {
    const url = endpoints.sessions
    return axios.get(url, headers)
}

export function getAllUnavaliableSessions() {
    const url = endpoints.sessionsUnavaliable
    return axios.get(url, headers)
}


//export const getAllLocations = getAllLocations




const exData = {
    "data": [
        {
            "id": 0,
            "public_charge_points": 0,
            "public_charge_points_ids": [
                0
            ],
            "name": {
                "en": "example",
                "fr": "affaire"
            },
            "status": "active",
            "description": {
                "en": "example",
                "fr": "affaire"
            },
            "detailed_description": {
                "en": "example",
                "fr": "affaire"
            },
            "geoposition": {
                "latitude": "string",
                "longitude": "string"
            },
            "address": {
                "en": "example",
                "fr": "affaire"
            },
            "post_code": "string",
            "region": "string",
            "city": "string",
            "country": "AF"
        }
    ],
    "links": {
        "first": "string",
        "last": "string",
        "prev": "string",
        "next": "string"
    },
    "meta": {
        "current_page": 0,
        "from": 0,
        "last_page": 0,
        "path": "string",
        "per_page": 0,
        "to": 0,
        "total": 0
    }
}


