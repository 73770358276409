

/*
  _____                .___.__                             
  _/ ____\___________  __| _/|__|   ____________ ___________ 
  \   __\/ __ \_  __ \/ __ | |  |  /  _ \___   // __ \_  __ \
   |  | \  ___/|  | \/ /_/ | |  | (  <_> )    /\  ___/|  | \/
   |__|  \___  >__|  \____ | |__|  \____/_____ \\___  >__|   
             \/           \/                  \/    \/       
                           piyanos.com..........ferdiozer.com
*/


import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
  Link,
  Outlet
} from "react-router-dom";

import HomePage from './pages/Home'

import './App.css';



const App = () => {
  let routes = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/location/:id", element: <HomePage /> },
    { path: "*", element: <NoMatch /> },
    // ...
  ]);
  return routes;
};


const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;



function NoMatch() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <h2>Bulunamadı!</h2>
      <p>
        <Link to="/">
          Anasayfaya Git
        </Link>
      </p>
    </div>
  );
}
