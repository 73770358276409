import {
    PushpinOutlined,
    InfoCircleOutlined,
    FileDoneOutlined,
    UserOutlined
} from '@ant-design/icons'


export const SidebarData = [
    {
        title: 'Map',
        path: '/',
        icon: <PushpinOutlined />,
        cName: 'nav-text'
    },
    {
        title: 'Help Center',
        path: '/help',
        icon: <InfoCircleOutlined />,
        cName: 'nav-text'
    },
    {
        title: 'Terms of Service',
        path: '/terms',
        icon: <FileDoneOutlined />,
        cName: 'nav-text'
    },
    {
        title: 'Privacy Policy',
        path: '/privacy',
        icon: <FileDoneOutlined />,
        cName: 'nav-text'
    },
    {
        title: 'Login or Sign-up',
        path: '/login',
        icon: <UserOutlined />,
        cName: 'nav-text'
    }
];