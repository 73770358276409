


import {
    LoadingOutlined
} from '@ant-design/icons'

export default ({
    title,
    icon,
    onClick,
    backgroundColor = "green",
    borderRadius = 5,
    color = "#FFF",
    loading = false
}) => {
    return (
        <div onClick={onClick} style={{
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor,
            cursor: 'pointer',
            borderRadius,
            color
        }}>
            {loading && <LoadingOutlined />}
            {title}
            {icon && icon}
        </div>
    )
}