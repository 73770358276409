

import React, { useState, useEffect, useRef } from 'react';

import Container from '../components/Container';


import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY } from '../config';
//import Maker from '../components/Maker';

import useSupercluster from "use-supercluster";

import useSwr from "swr";

import clusterSvg from '../assets/cluster.svg';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import SidebarDetail from '../components/SidebarDetail';


import mapPin from '../assets/map-pin.svg'
import mapPinV1 from '../assets/sarjon-locasyon.png'
import mapPinAcDc from '../assets/ac_dc_pin.png'
import mapPinAc from '../assets/ac_pin.png'
import mapPinDc from '../assets/dc_pin.png'

import ItemCard from '../components/ItemCard';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllLocations, getAllPoints, getAllUnavaliableSessions } from '../services/api';


export const MapContext = React.createContext({});


const fetcher = (...args) => fetch(...args).then(response => response.json());

const Marker = ({ children }) => children;

function App(props) {


    const [isDragging, toggleDragging] = useState(false);
    //const [zoom, setZoom] = useState(initialData.defaultZoom);


    const mapRef = useRef(null)
    const [bounds, setBounds] = useState(null);
    const [defaultCenter, setDefaultCenter] = useState({ lat: 41.0399999, lng: 29.0109176 });
    const [zoom, setZoom] = useState(10);

    const [sidebar, setSidebar] = useState(false);

    const [clickedLocation, setClickedLocation] = useState(null);

    const [crimes, setCrimes] = useState([]);
    const [locationPoints, setLocationPoints] = useState([]);
    const [locationSessions, setLocationSessions] = useState([]);


    useEffect(() => {
        getItemsFromApi()
        getPointsItemsFromApi()
        getSessionsItemsFromApi()
    }, [])

    useEffect(() => {
        if (sidebar) {
            getSessionsItemsFromApi()
        }
    }, [sidebar])


    const getItemsFromApi = async () => {
        try {
            const response = (await getAllLocations()).data
            console.log("getItemsFromApi", response)
            setCrimes(response.data.filter(l => l.public_charge_points > 0))
        } catch (error) {
            console.log("ERROR", error.response)
        }

    }

    const getPointsItemsFromApi = async () => {
        try {
            const response = (await getAllPoints()).data
            console.log("getPointsItemsFromApi", response)
            setLocationPoints(response.data.filter(v => v.status === "active" && v.accessType === "public"))
        } catch (error) {
            console.log("ERROR", error.response)
        }
    }

    const getSessionsItemsFromApi = async () => {
        try {
            const response = (await getAllUnavaliableSessions()).data
            console.log("getSessionsItemsFromApi", response)
            setLocationSessions(response.data)
        } catch (error) {
            console.log("ERROR", error.response)
        }
    }




    // const url = "https://data.police.uk/api/crimes-street/all-crime?lat=52.629729&lng=-1.131592&date=2019-10";
    // const { data, error } = useSwr(url, { fetcher });
    // const crimes = data && !error ? data.slice(0, 2000) : [];
    //  const crimes = locations;

    const points = crimes.map(crime => ({
        ...crime,
        properties: { cluster: false, crimeId: crime.id },
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(crime.geoposition.longitude),
                parseFloat(crime.geoposition.latitude)
            ]
        }
    }));

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        //  options: { radius: 75, maxZoom: 20 }
        options: { radius: 50, maxZoom: 20 }
    });

    // const getPointEvsesConnectTypes = (currentPoint)=>{
    //     const currentTypes =[]
    //     currentPoint?.evses.map(v=>currentTypes.push(v.currentType))
    //     return currentTypes
    // }

    const renderMapPinImg = (currentPoint) => {
        const currentTypes = []
        currentPoint?.evses.map(v => currentTypes.push(v.currentType))

        return currentTypes.includes("ac") ? mapPinAc : currentTypes.includes("dc") ? mapPinDc : mapPinAcDc
        //return currentTypes
    }




    ///  console.log("!!!!:HOME:props", props)

    return (
        <Container>


            <SidebarDetail
                visible={sidebar}
                sessions={locationSessions}
                headerTitle="Bağlayıcı Ayrıntıları"
                title={clickedLocation?.name?.en}
                subTitle={clickedLocation?.description.en}
                description={clickedLocation?.detailed_description.en}
                points_ids={clickedLocation?.public_charge_points_ids}
                points={locationPoints.filter(v => v.locationId === clickedLocation?.id && v.status === "active" && v.accessType === "public")}
                onClick={() => setSidebar(false)}
                onClickLeft={() => {
                    console.log("!!onClickLeft::", sidebar)
                    setSidebar(!sidebar)
                }}
            >

            </SidebarDetail>


            <div style={{
                height: '100vh', width: '100%',
                // paddingTop: 50
            }}>


                <MapContext.Provider value={{ isDragging, zoom }}>
                    <GoogleMapReact
                        ref={mapRef}
                        // defaultZoom={11}
                        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                        //bootstrapURLKeys={{ key: `PLACE_HOLDER` }}
                        yesIWantToUseGoogleMapApiInternals={true}
                        draggable={true}
                        options={{ clickableIcons: false }}
                        //defaultZoom={6}
                        // center={data.center}
                        // options={{ styles: myMapStyle }}
                        //defaultCenter={{ lat: -31.563910, lng: 147.154312 }}
                        // defaultCenter={{ lat: -33.727111, lng: 150.363181 }} 
                        //  onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
                        // defaultCenter={{ lat: 52.6376, lng: -1.135171 }}

                        onGoogleApiLoaded={({ map }) => {
                            mapRef.current = map;
                        }}
                        defaultCenter={defaultCenter}
                        defaultZoom={10}

                        onChange={({ zoom, bounds }) => {
                            setZoom(zoom);
                            setBounds([
                                bounds.nw.lng,
                                bounds.se.lat,
                                bounds.se.lng,
                                bounds.nw.lat
                            ]);
                        }}

                    >


                        {clusters.map(cluster => {
                            const [longitude, latitude] = cluster.geometry.coordinates;
                            const {
                                cluster: isCluster,
                                point_count: pointCount
                            } = cluster.properties;

                            const fPoints = locationPoints.find(v => v.locationId === cluster.id)

                            if (isCluster) {
                                return (
                                    <Marker
                                        key={`cluster-${cluster.id}`}
                                        lat={latitude}
                                        lng={longitude}
                                    >
                                        <div
                                            className="cluster-marker"
                                            style={{
                                                width: `${10 + (pointCount / points.length) * 20}px`,
                                                height: `${10 + (pointCount / points.length) * 20}px`,
                                                // backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                console.log("clicked:", cluster)
                                                const expansionZoom = Math.min(
                                                    supercluster.getClusterExpansionZoom(cluster.id),
                                                    17
                                                );
                                                mapRef.current.setZoom(expansionZoom);
                                                mapRef.current.panTo({ lat: latitude, lng: longitude });
                                            }}
                                        >
                                            {pointCount}
                                        </div>
                                    </Marker>
                                );
                            }

                            return (
                                <Marker
                                    key={`crime-${cluster.properties.crimeId}`}
                                    lat={latitude}
                                    lng={longitude}
                                >
                                    <button
                                        style={{
                                            position: "relative"
                                            // display: 'flex',
                                            //justifyContent: 'center',
                                            //  zIndex: 1000

                                        }}
                                        className="crime-marker">
                                        <img src={renderMapPinImg(fPoints)}
                                            style={{
                                                // width: 40
                                                width: 4 * zoom,
                                                position: "absolute",
                                                top: -(4 * zoom),
                                                left: -(2 * zoom)
                                            }}
                                            onClick={() => {

                                                console.log("!!clicked::cluster::", cluster)
                                                console.log("!!clicked::cluster:!fPoints!:", fPoints)
                                                if (clickedLocation?.properties?.crimeId !== cluster?.properties?.crimeId) {
                                                    setClickedLocation(cluster)
                                                }
                                                else {
                                                    setSidebar(false)
                                                    setClickedLocation(null)
                                                }
                                            }}
                                            alt="" />

                                        {
                                            clickedLocation?.properties?.crimeId === cluster?.properties?.crimeId
                                            &&
                                            <ItemCard
                                                evses={fPoints?.evses}
                                                onClickClose={() => {
                                                    setSidebar(false)
                                                    setClickedLocation(null)
                                                }}
                                                cluster={cluster}
                                                onClick={() => {
                                                    //noktaları yeniden getir (güncel kalması için)
                                                    getPointsItemsFromApi()
                                                    setSidebar(true)
                                                }}
                                                title={clickedLocation?.name?.en}
                                                subTitle={clickedLocation?.description.en}
                                                description={clickedLocation?.address?.en}
                                                points_ids={clickedLocation?.public_charge_points_ids}
                                                points={locationPoints.filter(v => v.locationId === clickedLocation?.id)}

                                            />

                                        }



                                    </button>
                                </Marker>
                            );
                        })}

                    </GoogleMapReact>
                </MapContext.Provider>
            </div>


        </Container>
    );
}

export default App;
