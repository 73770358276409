import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';

import {
    MenuOutlined,
    DownOutlined
} from '@ant-design/icons'


function Navbar() {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>

            <div className='navbar'>
                <Link to='#' className='menu-bars'>
                    <MenuOutlined onClick={showSidebar} style={{ color: "#FFF" }} />
                    {/* { <FaIcons.FaBars onClick={showSidebar} />} */}
                </Link>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav-menu-items' onClick={showSidebar}>
                    <li className='navbar-toggle'>
                        <Link to='#' className='menu-bars'>
                            SOL
                            {/* { <AiIcons.AiOutlineClose />} */}
                        </Link>
                    </li>
                    {SidebarData.map((item, index) => {
                        return (
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                                    SAĞ
                                    {
                                        //item.icon
                                    }
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>

        </>
    );
}

export default Navbar;