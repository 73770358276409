

import React, { useState } from 'react';

import { colors, siteInfo } from "../config";

import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';

import {
    MenuOutlined,
    DownOutlined,
    CloseCircleOutlined,
    ArrowLeftOutlined,
    LeftOutlined
} from '@ant-design/icons'
import Button from "./Button";
import Navbar from "./Navbar";

import mapPin from '../assets/map-pin.svg'
import connectorType2 from '../assets/connector-type2.png'
import AvailableConnectorRow from './AvailableConnectorRow';

function SidebarDetail({
    visible,
    sessions = [],
    onClick = () => { },
    onClickLeft = () => { },
    children,
    title,
    description,
    subTitle,
    points_ids = [],
    points = [],
    headerTitle = "Connector Details"
}) {


    // console.log("!!!sessions::", { sessions })

    const renderPoints = []

    points.map((point, i) => {
        const type = point[i]?.type
        point.evses.map(v => {

            const pointSessions = sessions.filter(v => v.chargePointId == point.id)
            // console.log("v.map:point:evses::!", point)
            // console.log("v.map:status!", v.status)
            // console.log("v.map:networkStatus!", point?.networkStatus)
            // console.log("v.map:hardwareStatus!", v.hardwareStatus)
            // console.log("v.map:pointSessions!", pointSessions)

            renderPoints.push(
                <AvailableConnectorRow
                    isActive={sessions?.active?.filter(v => v.chargePointId == point.id).length > 0 ? true : false}
                    isPending={sessions?.pending?.filter(v => v.chargePointId == point.id).length > 0 ? true : false}
                    // lastSession={pointSessions.length > 0 ? pointSessions[pointSessions.length - 1] : null}
                    key={v.id}
                    id={v.physicalReference}
                    // status={v.status === "active"}
                    status={point.status === "active"}
                    hardwareStatus={v.hardwareStatus}
                    networkStatus={point?.networkStatus}
                    typeText={type}
                    ac={v.currentType}
                    kw={v.maxPower}
                />
            )
        })
    })


    return (
        <div
            style={{

                position: 'absolute',
                height: '100vh',

                zIndex: 99,
                display: 'flex',
                flexDirection: 'column',
                //paddingTop: 50
            }}
            className={visible ? "sidebar-left-v1 active" : "sidebar-left-v1"}>

            <div style={{
                overflowY: 'auto',
                paddingTop: 80,
                paddingRight: 10,
                paddingLeft: 10,

            }}>




                <div style={{
                    display: 'flex',
                    //   zIndex: 9999,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    //  alignItems: 'center',
                    // flex: 1
                }}>
                    <ArrowLeftOutlined onClick={onClickLeft}
                        style={{
                            color: '#FFF',
                        }} />

                    <div style={{ color: '#FFF' }}>
                        {headerTitle}
                    </div>
                    <div />
                </div>






                <div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderColor: 'red',
                        paddingBottom: 10,
                        paddingTop: 20
                    }}>
                        <div style={{
                            backgroundColor: '#FFF',
                            height: 30,
                            width: 30,
                            borderRadius: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img src={mapPin}
                                style={{
                                    width: 20,
                                    height: 20
                                }} />
                        </div>

                        <div style={{
                            paddingLeft: 10
                        }}>
                            <div style={{
                                textAlign: 'left',
                                fontWeight: 700,
                                fontSize: 14,
                                color: '#FFF'
                            }}>
                                {title}
                            </div>
                            <div style={{ color: '#FFF' }}>
                                {subTitle}
                            </div>
                        </div>

                    </div>

                    {renderPoints}

                    {
                        // points.map((point, i) => {

                        //     console.log("points.map:::!", point)
                        //     //   const evses = points[i]?.evses || []
                        //     point.evses.map(v => {
                        //         console.log("v.map:::!", point)
                        //         return (
                        //             <h1>

                        //                 Deneme9
                        //             </h1>
                        //         )
                        //     })
                        //     return (
                        //         <h1>

                        //             Deneme
                        //         </h1>
                        //     )
                        // })
                    }

                    {
                        //  evses.map(v => (
                        //      <AvailableConnectorRow
                        //          key={v.id}
                        //          id={v.physicalReference}
                        //          status={v.status === "active"}
                        //          hardwareStatus={v.hardwareStatus}
                        //          networkStatus={points[0]?.networkStatus}
                        //          typeText={v.connectors[0]?.type}
                        //          ac={v.currentType}
                        //          kw={v.maxPower}
                        //      />
                        //  ))
                    }

                    {/* <AvailableConnectorRow id="5462" status={true} />
                    <AvailableConnectorRow id="895612" status={false} /> */}

                </div>



                <div style={{
                    paddingTop: 50,
                    color: '#FFF'
                }}>
                    {description}
                </div>




            </div>











            {children}
        </div>

    );
}

export default SidebarDetail;


/*
        <div style={{
            //  backgroundColor: "red",
            ////////  position: 'absolute',
            // height: 50,
            // top: 0, left: 0,
            // right: 0,
            //  zIndex: visible ? 9999 : 0,
            //////    zIndex: 99,
            //////   paddingTop: 50
        }}>

        */