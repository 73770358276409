import Button from "./Button"

import { Link } from 'react-router-dom';

import mapPin from '../assets/sarjon-locasyon.png'
import connectorType2 from '../assets/connector-type2.png'
import { colors } from "../config";

import {
    CloseOutlined,
} from '@ant-design/icons'


export default ({
    cluster,
    onClick = () => { },
    onClickClose = () => { },
    title = "",
    subTitle = "",
    description = "",
    points_ids = [],
    evses = [],
}) => {

    return (
        <div style={{
            zIndex: 999,
            backgroundColor: '#FFF',
            position: 'absolute',
            borderRadius: 10,
            padding: 10,
        }}>

            <CloseOutlined onClick={onClickClose} style={{ position: 'absolute', top: 10, right: 10 }} />


            <div style={{
                display: 'flex',
                alignItems: 'center',
                borderColor: 'red',
                borderBottom: `0.5px solid ${colors.primary}`,
                paddingBottom: 10,
                minWidth: 200
            }}>
                <img src={mapPin}
                    style={{
                        width: 20,
                        // height: 20
                    }} />

                <div style={{
                    paddingLeft: 10
                }}>
                    <div style={{
                        textAlign: 'left',
                        fontWeight: 700,
                        fontSize: 14,
                    }}>
                        {title}
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        {subTitle}
                    </div>
                </div>

            </div>

            <div style={{ paddingBottom: 20 }}>

                <div style={{ marginTop: 10, marginBottom: 10, display: 'flex' }}>
                    {
                        evses.map((v, i) => {
                            const kw = Number(v.maxPower) / 1000
                            const { currentType } = v
                            const typeText = v.connectors[0]?.type
                            return (
                                <div key={i} style={{ marginRight: 5 }}>
                                    <img src={connectorType2} />
                                    <div style={{ fontSize: 10 }}>
                                        <span className="tcap">{typeText}</span>
                                        <br />

                                        <span>
                                            {`${kw}kW `}
                                            <span style={{ marginLeft: 0 }} className="tcap">{currentType}</span>
                                        </span>

                                    </div>
                                </div>
                            )
                        })


                    }




                </div>


                {
                    description
                    &&
                    <div style={{ textAlign: 'left' }}>
                        {description}
                    </div>
                }


            </div>
            <Button
                onClick={onClick}
                title="Detay" />
            {/* <Link to={`/location/${cluster?.properties?.crimeId}`}>
                <Button
                    onClick={onClick}
                    title="Detay" />
            </Link> */}

        </div>
    )
}