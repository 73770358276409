

const url = process.env.NODE_ENV !== "development" ? "https://deprem.piyanos.com" : "http://localhost:3070"


export const colors = {
    primaryOld: "#355C7D",
    primary: "#43AC34",
    white: "#FFFFFF"
}


export const API_URL = `${url}/api`;


export const GOOGLE_MAP_API_KEY = "AIzaSyDYJr3bREWMiBqw7jDc3yrqAaK72ZSsNvQ"



export const siteInfo = {
    logoWhite: "https://w4r8m6r9.rocketcdn.me/content/themes/ampeco/dist/assets/images/ampeco-logo-white.png"
}


export const ampecoToken = "e1e0f452-8eae-4478-a5db-855e1e1b0821";