

import React from 'react';


import './Navbar.css';

import connectorType2 from '../assets/connector-type2.png'

export default ({
    id,
    status,
    typeText = "Type2",
    ac = "ac",
    kw = "kw",
    hardwareStatus = "",
    networkStatus = "",
    isActive = false,
    isPending = false
}) => {

    kw = Number(kw) / 1000

    /**
     *session status "pending" "active" "finished" "failed" "expired"
     */



    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingTop: 10,
            alignItems: 'center',
            flex: 1
        }}>


            <div style={{ color: '#FFF', flex: 0.4 }}>
                <div>
                    ID: <span style={{ fontWeight: 700 }}>
                        {id}
                    </span>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 5,
                    paddingTop: 5
                }}>
                    {
                        status && networkStatus == "available"
                            ?
                            <>
                                <div style={{
                                    backgroundColor: 'rgb(0, 159, 18)', height: 10, width: 10, borderRadius: 5
                                }} />
                                <span style={{ fontSize: 11, color: 'rgb(0, 159, 18)' }}>

                                    {isActive && "Dolu"}
                                    {isPending && "Bekleniyor"}
                                    {(!isActive && !isPending) && "Müsait"}



                                    {
                                        //  (!isActive || !isPending)
                                        //      ?
                                        //      "Müsait"
                                        //      :
                                        //      isActive
                                        //          ?
                                        //          "Dolu"
                                        //          :
                                        //          isPending
                                        //              ?
                                        //              "Bekleniyor"
                                        //              :
                                        //              "Müsait"
                                    }

                                    {
                                        //lastSession?.status != "failed"
                                        //    ?
                                        //    lastSession?.status == "pending"
                                        //        ?
                                        //        "Bekleniyor"
                                        //        :
                                        //        lastSession?.stoppedAt
                                        //            ?
                                        //            "Dolu"
                                        //            :
                                        //            "Müsait"
                                        //    :
                                        //    "Müsait"
                                    }

                                </span>
                            </>
                            :
                            <>
                                <div style={{
                                    backgroundColor: 'rgb(202, 81, 43)', height: 10, width: 10, borderRadius: 5
                                }} />
                                <span style={{ fontSize: 11, color: 'rgb(202, 81, 43)' }}>Hizmet Dışı</span>
                            </>
                    }
                </div>
            </div>


            <div style={{ flex: 0.3, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{
                }}>


                    <div style={{
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        height: 28,
                        width: 28,
                        borderRadius: 14,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img src={connectorType2}
                            style={{
                                width: 20,
                                height: 20
                            }} />
                    </div>

                </div>

                <div style={{ flex: 0.3, fontSize: 10, color: '#FFF' }}>
                    <span className="tcap">{typeText}</span>
                    <br />

                    <span>
                        {`${kw}kW `}
                        <span style={{ marginLeft: 0 }} className="tcap">{ac}</span>
                    </span>

                </div>
            </div>



            {
                //  networkStatus=="long-term unavailable"    = servis dışı
            }
            {
                networkStatus == "available"
                    ?
                    (hardwareStatus == "available" && status)
                        ?
                        <div style={{ fontSize: 12, color: '#FFF', display: 'flex', flex: 0.5 }}>Cihaz Aktif</div>
                        :
                        <div style={{ fontSize: 12, color: '#FFF', display: 'flex', flex: 0.5 }}>Cihaz Aktif</div>
                    :
                    <div style={{ fontSize: 12, color: '#FFF', display: 'flex', flex: 0.5 }}>
                        Cihaz Servis Dışı
                    </div>
            }

        </div>
    );
}


